export const environment = {
  production: false,
  ambiente: 'preproduction',
  server: "https://back-videoconsulta-preprod.cdt.com.ar"

//  server: "https://springboot-videoconsulta-preprod:8080"


//  server: 'https://urg-videoconsulta-backend-preprod-dot-urg-easydoc-205820.appspot.com'
}
